import React from 'react'

import Layout from '../components/Layout'
import Container from '../components/Container'

import './Form.scss'

const Input = ({ name, label, type = 'text', ...others }) =>
  <div className='input-wrapper'>
    {label ? <label htmlFor={name}>{label}</label> : null}
    <input type={type} name={name} {...others} />
  </div>

const TextArea = ({ name, label, type = 'text', ...others }) =>
  <div className='input-wrapper'>
    {label ? <label htmlFor={name}>{label}</label> : null}
    <textarea type={type} name={name} {...others} />
  </div>

const Form = location =>
  <Layout location={location} title='Pawsome Dogs | Chicago Dog Training | Contact' description='Tell us about you and your dog. Let us know what kind of dog training or services your are interested in. Fill out our contact form to get started!'>
    <main className='Form'>

      <Container className='Form--container'>

        <form action="https://formspree.io/f/xvodwplb" method="POST" className='Form--container--form'>

          <input type="hidden" name="_next" value="https://www.pawsomedogs.net/Sent/" />
          <input type="hidden" name="_subject" value="Pawsome Dogs Website Form Submission" />
          <input type="hidden" name="_cc" value="nomoreanalogstudio@gmail.com" />
          <input type="text" name="_gotcha" style={{ display: "none" }} />

          <noscript>
            <p>This form won’t work with Javascript disabled</p>
          </noscript>

          <h3>About You</h3>

          <div className='Form--container--form--group'>
            <Input name='firstName' label='First name: *' required />
            <Input name='lastName' label='Last name: *' required />
          </div>

          <div className='Form--container--form--group'>
            <Input name='email' label='Email: *' required />
            <Input name='phone' label='Phone:' />
          </div>

          <Input name='address1' label='Address 1 *:' required />
          <Input name='address2' label='Address 2:' />

          <div className='Form--container--form--group'>
            <Input name='city' label='City:' />
            <Input name='state' label='State:' />
            <Input name='zip' label='Zip:' />
          </div>

          <TextArea name='bestTimes' label='Best times and days for lessons:' />
          <Input name='hearAboutUs' label='How did you hear about us?' />

          <h3>About Your Dog</h3>

          <Input name='dogsName' label="Dog's name: *" required />

          <div className='Form--container--form--group'>
            <Input name='breed' label='Breed: *' required />
            <Input name='age' label='Age: *' required />
          </div>

          <Input name='whereIsDogFrom' label='Where is the dog from?' />
          <TextArea name='allergies' label='Food allergies:' />
          <TextArea name='medicalIssues' label='Medical issues:' />
          <TextArea name='issuesInDetail' label='List any issues you may be having with your dog in detail if any or other additional comments:' />
          <TextArea name='doneSoFar' label='What you have done so far for the behavior?' />
          <TextArea name='hasBitten' label='Has your dog ever bitten someone? If yes, please describe: *' required />

          <p>If you do not hear from us in 48 hours, check your spam or junk mail folder.</p>

          <button type='submit'>SUBMIT</button>

        </form>

      </Container>

    </main>
  </Layout>

export default Form
